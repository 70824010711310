import { Component, OnInit } from '@angular/core';
import { Web3Service } from "../../services/contract/web3.service";
import { Observable, map, tap } from 'rxjs';
import { Sweetalert2stepsService } from 'src/app/services/sweetalert2steps.service';
import { toWei } from 'src/app/helpers/utils';

declare var particlesJS: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public userBalance: any;
  public dataStatus$!: Observable<any>;
  public showLoader: boolean = false;

  constructor(
    private web3: Web3Service,
    private alertStepSrv: Sweetalert2stepsService
  ) { }

  ngOnInit(): void {
    particlesJS.load('particles-js', '../assets/particles/particlesjs3.json', null);

    this.dataStatus$ = this.web3.accountStatus$
    .pipe( 
      map((data: any[]) => data[0]),
      // tap((data) => console.log('data', data)),
    );
  }

  async connect() { return this.web3.connectAccount(); }

  async logout(){ return this.web3.logout();}

  /**
   * Mint a new token
   */
  async Mint() {
    try {

      this.showLoader = true;
      const mintPrice = await this.web3.mint_price();
      // console.log('mintPrice', mintPrice);
      this.showLoader = false;

      const result = await this.alertStepSrv.showStepsNative({
        actionMessage: 'Are you sure you want to mint a new token?',
        checkBalanceParams: {
          amount: mintPrice
        },
        contractParams: {
          method: 'mint',
          params: [mintPrice]
        }
      });

      if (!result.status) {
        return await this.alertStepSrv.showBasicAlert(result.data.message, 'error');
      }

      this.alertStepSrv.showAlertWithTxHash({ transactionHash: result.data.transactionHash });
      return;

    } catch (error: any) {
      console.log('Error on HomeComponent.Mint', error);
      return
    }
  }

  verMas(){
    document.getElementById("ver-mas")?.classList.toggle("show");
  }

}
