// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  projectName: "Priority Pass EJADVILLAGE",
  API_URL: '#',
  urlWeb: '#',
  configUrlAbi: "/assets/abi/erc721s.json",
  contractAddress: "0xEAF83b525a80B4d9DDD97680E0c7B299792C07BB",
  urlTokenLogo: "#",
  mainToken: {
    contract: "#",
    name: "#",
    symbol: "#",
    decimals: 18,
  },
  chain: {
    infuraId: "356256bc3fcf42de88d2bc2e129ea5d9",

    // chainId: 56,
    // chainIdMetamask: "0x61",
    // chainName: "BNB Smart Chain Testnet",
    // rpc: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    // rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    // blockExplorerUrls: ["https://testnet.bscscan.com/"],
    // scan: "https://testnet.bscscan.com/tx/",
    // scanNft: "https://testnet.bscscan.com/token/",


    // Mainnnet 
    chainId: 56,
    chainIdMetamask: "0X38",
    chainName: "BNB Smart Chain Mainnet",
    rpc: "https://rpc-bsc.bnb48.club/",
    rpcUrls: ["https://bsc-dataseed1.defibit.io/"],
    blockExplorerUrls: ["https://bscscan.com/"],
    scan: "https://bscscan.com/tx/",
    scanNft: "https://bscscan.com/token/",

    nativeCurrency: {
      web3ModalNetwork: "binance",
      network: "BNB",
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },

  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
