import { Inject, Injectable } from '@angular/core';
import { WEB3 } from '../../core/web3';
import { Subject } from 'rxjs';
import Web3 from 'web3';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { provider } from 'web3-core';
import { AbiService } from 'src/app/services/contract/abi.service';
import { environment } from 'src/environments/environment';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import BigNumber from 'bignumber.js';

@Injectable({
  providedIn: 'root'
})
export class Web3Service {

  /** Instancia para obtener wallets conectadas */
  public accountStatusSource = new Subject<any>();
  accountStatus$ = this.accountStatusSource.asObservable();

  public web3Modal;
  public web3js: any;

  /** Instancia del provider */
  public provider: any;

  /** Tipo de provider utilizado para conectar */
  private providerType: any;

  public accounts: any | undefined;
  public balance: string | undefined;
  public uToken: any;

  public erc20ABI = '/assets/abi/erc20.json';
  public erc721ABI = '/assets/abi/erc721.json';
  public erc721SABI = '/assets/abi/erc721s.json';

  constructor(
    @Inject(WEB3) private web3: Web3,
    public abiService: AbiService
  ) {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        chainId: environment.chain.chainId,
        options: {
          network: 'binance',
          chainId: environment.chain.chainId,
          infuraId: environment.chain.infuraId, // required,
          rpc: {
            [environment.chain.chainId]: environment.chain.rpc + '',
          },
          qrcodeModalOptions: {
            mobileLinks: [
              'rainbow',
              'metamask',
              'argent',
              'trust',
              'imtoken',
              'pillar'
            ]
          }
        },
        display: {
          description: "Scan with a wallet to connect",
        },
      },
    };

    this.web3Modal = new Web3Modal({
      network: "binance", // replace mainnet to binance
      cacheProvider: true, // optional
      providerOptions, // required
      theme: {
        background: "#d0dd28",
        main: "#323232",
        secondary: "rgb(136, 136, 136)",
        border: "rgba(195, 195, 195, 0.14)",
        hover: "rgb(16, 26, 32)"
      }
    });
  }

  async connectAccount() {
    this.provider = await this.web3Modal.connect(); // set provider

    // create web3 instance
    // console.log("provider", this.provider);
    if (this.provider) {
      this.web3js = new Web3(this.provider);
    }

    /** Capturar tipo de provider */
    const providerTypeToParse = window.localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER');
    this.providerType = `${providerTypeToParse}`.replace(/"/g, '');

    /** Validar si el provider es WalletConnect */
    const wcVerify = await this.checkWalletConnectProviderConnection();
    if (!wcVerify) return;

    /** Obtener wallet conectada y almacenar */
    this.accounts = await this.web3js.eth.getAccounts();
    this.accountStatusSource.next(this.accounts);

    return this.accounts;
  }

  /**
   * Validar si el provider es WalletConnect
   * @returns 
   */
  async checkWalletConnectProviderConnection() {

    /** Validar si corresponde al provider */
    if (this.providerType !== 'walletconnect') return true;

    /** Validar si es la red correcta */
    const providerChainId = await this.web3js.eth.net.getId();
    if (providerChainId == environment.chain.chainId) return true;

    await this.web3Modal.clearCachedProvider();
    await this.provider.close();
    this.provider = null;

    Swal.fire({
      title: environment.projectName,
      icon: 'error',
      text: `Network error, please connect to ${environment.chain.chainName}`,
    });
    return false;
  }

  /**
   * Validar estado de la red
   */
  async checkNetworkLocal() {
    const chainId = await this.web3js.eth.net.getId();
    if (chainId != environment.chain.chainId) {
      const modalChangeChain = await Swal.fire({
        title: environment.projectName,
        icon: 'warning',
        text: `Please switch to ${environment.chain.chainName}`,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Change',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            const runChange = await this.changeChainIdOrAdd();
          } catch (err: any) {
            // console.log('modal error', err);
            Swal.showValidationMessage(`${err.message}`);
          }
        },
      });

      // console.log({modalChangeChain});
    }
  }

  /**
  * Disparar método para cambiar de red o añadirla
  * @returns
  */
  async changeChainIdOrAdd() {
    try {
      const tryChange = await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: environment.chain.chainIdMetamask }],
      });

      // console.log({tryChange});

      return tryChange
    } catch (err: any) {

      /** Si no tiene la red registrada */
      if (err.code === 4902) { return await this.addChainId(); }

      /** Si tiene trasacciones pendientes por ejecutar */
      if (err.code === 4001) {
        err.message = 'Please, confirm the request for change of network';
      }

      /** Si tiene trasacciones pendientes por ejecutar */
      if (err.code === -32002) {
        err.message = 'You have pending requests on your wallet. Please, check on your wallet before continuing';
      }

      throw err;
    }
  }

  /**
   * Añadir red a metamask
   * @returns 
   */
  async addChainId() {
    try {
      const wasAdded = await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: environment.chain.chainIdMetamask,
            rpcUrls: environment.chain.rpcUrls,
            chainName: environment.chain.chainName,
            nativeCurrency: environment.chain.nativeCurrency,
            blockExplorerUrls: environment.chain.blockExplorerUrls,
          }
        ],
      });

      // console.log({wasAdded});
      return wasAdded;
    } catch (err: any) {
      alert("Please connect to Binance Smart Chain");
    }
  }

  /**
   * Escuchar eventos de la wallet
   */
  eventsAll() {
    // Subscribe to accounts change
    this.provider.on("accountsChanged", (accounts: string[]) => {
      // console.warn("accountsChanged", accounts);
      this.accountStatusSource.next(accounts)
      window.location.reload();
    });

    // Subscribe to chainId change
    this.provider.on("chainChanged", (chainId: number) => {
      // console.log("chainChanged", chainId);
      window.location.reload();
    });

    // Subscribe to provider connection
    this.provider.on("connect", (info: { chainId: number }) => {
    });

    // Subscribe to provider disconnection
    this.provider.on("disconnect", (error: { code: number; message: string }) => {
      // console.log("disconnect", error);
      this.logout(true);
    });
  }

  /**
   * Desconectar wallet
   * @param reload 
   */
  async logout(reload = true) {
    await this.web3Modal.clearCachedProvider();

    if (this.providerType == 'walletconnect') { await this.provider.close(); }

    this.provider = null

    this.accountStatusSource.next(null);
    window.localStorage.clear();

    if (reload) { window.location.reload(); }
  }




  /**
   * TODO: pendiente por revisar
   * @param account 
   * @returns 
   */
  async accountInfo(account: any[]) {
    const initialvalue = await this.web3js.eth.getBalance(account);
    this.balance = this.web3js.utils.fromWei(initialvalue, 'ether');
    return this.balance;
  }

  /**
   * TODO: pendiente por revisar
   * @returns 
   */
  async checkNetwork() {
    const networkId = await this.web3js.eth.net.getId();
    console.log("networkId", networkId);
    if (environment.chain.chainId != networkId) {
      alert("Please connect to Binance Smart Chain");
      return false;
    }

    return true;
  }



  /** ===============================================================
   *                      Native Methods
   ================================================================ */

  /**
   * Obtiene el balance de token nativo
   * @param account
   * @returns
   */
  async balanceOfNative(account: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.web3js.eth.getBalance(account, (err: any, res: any) => {
        if (err) {
          console.log(err)
          reject(err)
        } else {
          resolve(res)
        }
      })
    })
  }

  /**
   * Válida balance de usuario en token nativo
   * @param amount
   * @returns
   */
  async checkUserBalanceNative(amount: string) {
    const balance = await this.balanceOfNative(this.accounts[0]);
    const balanceParse = new BigNumber(`${balance}`);
    return balanceParse.isGreaterThanOrEqualTo(amount);
  }




  /* =======================================================
   *                     ERC20 Methods
   * ===================================================== */

  async erc20_approve(erc20Contract: string, contractAddress: string, amount: string) {
    const [account] = this.accounts;
    return await this.calculateAndCallCustomABI({
      contractAddress: erc20Contract,
      method: 'approve',
      params: [contractAddress, amount],
      callType: 'send',
      optionals: { from: account },
      urlABI: this.erc20ABI
    });
  }

  /**
   * Consultar balance de token ERC20
   * @param contractAddress               Dirección del contrato
   * @param account                       Dirección de la wallet
   * @returns 
   */
  async erc20_balanceOf(contractAddress: string, account: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: contractAddress,
      method: 'balanceOf',
      params: [account],
      callType: 'call',
      urlABI: this.erc20ABI
    });
  }

  /**
   * Verificar balance de usuario
   * @param contractAddress               Dirección del contrato
   * @param amount                        Cantidad a transferir en WEI
   * @returns 
   */
  async erc20_checkUserBalance(contractAddress: string, amount: any) {
    const [account] = this.accounts;
    const balance = await this.erc20_balanceOf(contractAddress, account);
    const balanceParse = new BigNumber(balance);
    return balanceParse.isGreaterThanOrEqualTo(amount);
  }




  /* =======================================================
   *                   MINT METHODS
   * ===================================================== */
  async mint_price() {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contractAddress,
      method: 'PRICE',
      callType: 'call',
      urlABI: this.erc721SABI
    });
  }

  async mint(amount: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.contractAddress,
      method: 'mint',
      callType: 'send',
      optionals: {value: amount},
      urlABI: this.erc721SABI
    });
  }




  /** ===============================================================
 *               Méthodo genérico para llamadas al SC
 * ================================================================
 * @param method
 * @param params
 * @param callType        'call' and 'send'
 */
  async calculateAndCall(
    method: any,
    params?: any,
    callType = 'send',
    optionals: any = {}
  ) {
    try {
      const contractMethod = !params
        ? this.uToken.methods[method]()
        : this.uToken.methods[method](...params);

      if (callType === 'send') {
        const [account] = this.accounts;
        optionals.from = account;

        const gasFee = await contractMethod.estimateGas(optionals);
        console.log("gas", gasFee);

        optionals.gas = gasFee;
      }

      const result = await contractMethod[callType](optionals);

      if (callType === 'send') {
      }

      return result;
    } catch (err: any) {
      // this.sweetAlertSrv.showError('Transacción fallida');
      console.log('Error on ContractService@calculateAndCall', err);
      throw new Error(err);
    }
  }

  /** ===============================================================
   *       Méthodo genérico para llamadas al SC personalizado
   * ================================================================
   * @param data
   * @param data.contractAddress
   * @param data.method
   * @param data.params
   * @param data.callType           'call' / 'send'
   * @param data.optionals
   * @param data.urlABI
   */
  async calculateAndCallCustomABI(data: any) {
    const {
      contractAddress,
      method,
      params = null,
      callType = 'send',
      optionals = {},
      urlABI = this.erc20ABI,
    } = data;

    try {
      // Cargar ABI del contrato
      const contractABI: any = await this.abiService.getABIByUrl(urlABI);

      // cargamos la abi de contracto secundarios con el metodo que necesitamos
      const uToken = this.getAbiContract(
        [contractABI[method]],
        contractAddress
      );

      const contractMethod = !params
        ? uToken.methods[method]()
        : uToken.methods[method](...params);

      if (callType === 'send') {
        const [account] = this.accounts;
        optionals.from = account;

        const gasFee = await contractMethod.estimateGas(optionals);

        optionals.gas = gasFee;
      }

      const result = await contractMethod[callType](optionals);

      return result;
    } catch (err: any) {
      console.log({ params: data });
      console.log('Error on ContractService@calculateAndCallCustomABI', err);
      throw new Error(err);
    }
  }

  /**
  * Obteber nueva instancia WEB3 de un SC a través del ABI ingresado
  * @param token_abi             ABI Cargado
  * @param token_address         Dirección del SC
  * @returns
  */
  getAbiContract(token_abi: any, token_address: any) {
    let uToken: any = new this.web3js.eth.Contract(token_abi, token_address);
    return uToken;
  }
}

