import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Web3Service } from "./services/contract/web3.service";
import { AbiService } from './services/contract/abi.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  authenticated: boolean = false;
  data: string[] | undefined;


  constructor(
    private web3: Web3Service,
    private abiSrv: AbiService
  ) {
    console.log("MAIN:", environment.chain.chainId);
    console.log("MAIN:", environment.chain.rpc);
    // this.test();
  }


  Connect() {
    this.web3.connectAccount().then(response => {
      console.log(response);
      this.data = response
    });
  }

  async test(){
    const abi: any = await this.abiSrv.parseABI('/assets/abi/erc721s.json');
    console.log(abi);
  }


}
