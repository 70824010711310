<div class="section-home" id="particles-js">
    <div class="degd1"></div>
    <div class="degd2"></div>
    <div class="container">
        <div class="row min-vh-100 align-items-stretch p-5">

            <!-- Banner -->
            <div class="col-12 col-lg-6 align-self-center">
                <div class="info">
                    <div class="logo text-center text-lg-start">
                        <img src="assets/img/LOGO2.png" alt="">
                    </div>
                    <div class="parrf">
                        <div class="item-des d-flex align-items-start">
                            <img src="assets/img/check.png" alt="">
                            <span>Descuentos de accesos para EJADPRO E-Learning.</span>
                        </div>
                        <div class="item-des d-flex align-items-start">
                            <img src="assets/img/check.png" alt="">
                            <span>Priority Pass EJADVILLAGE.</span>
                        </div>
                        <div class="item-des d-flex align-items-start">
                            <img src="assets/img/check.png" alt="">
                            <span>Descuento en el hospedaje en los eco hoteles.</span>
                        </div>
                        <div class="item-des d-flex align-items-start">
                            <img src="assets/img/check.png" alt="">
                            <span>EJADVILLAGE y alianzas.</span>
                        </div>
                        <div class="item-des d-flex align-items-start">
                            <img src="assets/img/check.png" alt="">
                            <span>Acceso a EJADPOO, gestión de activos descentralizados.</span>
                        </div>
                        <div class="more" id="ver-mas">
                            <div class="item-des d-flex align-items-start">
                                <img src="assets/img/check.png" alt="">
                                <span>Hasta el 11% anual del valor del NFT adquirido.</span>
                            </div>
                            <div class="item-des d-flex align-items-start">
                                <img src="assets/img/check.png" alt="">
                                <span>Kit Desarrolla tu Potencial con EJAD.</span>
                            </div>
                            <div class="item-des d-flex align-items-start">
                                <img src="assets/img/check.png" alt="">
                                <span>Membresía de embajador de la Marca EJAD.</span>
                            </div>
                            <div class="item-des d-flex align-items-start">
                                <img src="assets/img/check.png" alt="">
                                <span>Priority Pass a eventos de EJAD, LiBERATEMPRENDIENDO, DAVAR22, y demás alianzas en 
                                    su lanzamientos de nuevos proyectos y productos. </span>
                            </div>
                            <div class="item-des d-flex align-items-start">
                                <img src="assets/img/check.png" alt="">
                                <span>Mentoria privada lectura del árbol de la vida personal y planificación de resultados.</span>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 d-flex justify-content-center justify-content-lg-start">
                        <button class="btn-one" (click)="verMas()">Ver más</button>
                    </div>
                </div>
            </div>

            <!-- Mint Form -->
            <div class="col-12 col-lg-6 offset-xl-2 col-xl-4 mt-5 mt-lg-0 align-self-center">
                <div class="cards-glass">
                    <div class="d-flex flex-column justify-content-center align-items-center h-100">
                        <div class="label pe-2 fw-bold mb-3 text-center">
                            COLECCIÓN BERESHIT
                            <ng-container *ngIf="(dataStatus$ | async) as account" [ngTemplateOutlet]="renderWalletBtn" [ngTemplateOutletContext]="{addr: account}"></ng-container>
                        </div>

                        <div class="img-nft">
                            <img src="/assets/img/nft.png" alt="">
                        </div>

                        <!-- <select class="form-select mb-3" aria-label="Default select example">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select> -->

                        <ng-container *ngIf="(dataStatus$ | async) as account; then renderBtns; else connectAccountBtn"></ng-container>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<ng-template #renderBtns>
    <button *ngIf="!showLoader" class="btn-one" (click)="Mint()">MINT</button>

    <button *ngIf="showLoader" class="btn-one" type="button" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="sr-only">Loading...</span>
    </button>
</ng-template>

<ng-template #renderWalletBtn let-addr="addr">
    <button class="btn-one" (click)="logout()">
        {{addr | truncateWalletAddress}}
    </button>
</ng-template>

<ng-template #connectAccountBtn>
    <button class="btn-one" (click)="connect()">CONNECT</button>
</ng-template>